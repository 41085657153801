import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ButtonPrimary,
  Typography,
  Notice,
  BillingAddress,
  Divider,
  TrustBox,
  LinkNavigation,
  YourPrice,
} from '../../components/atoms';
import { PaymentDetails } from '../../components/molecules';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './PaymentStyles';
import { StepContext, steps, Step } from '../../contexts/StepContext';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, startOfMonth } from 'date-fns';
import creditCardType from 'credit-card-type';
import * as yup from 'yup';
import paymentSchema from './PaymentSchema.json';
import axios from 'axios';
import clsx from 'clsx';
import { convertDateToDlgFormat } from '../../utils/dateFormattingUtils';

const MASTERCARD = 'MASTERCARD';
const MAESTRO = 'MAESTRO';
const VISA = 'VISA';
const AMERICAN_EXPRESS = 'AMEX';

const libraryToLocalCardTypeMapping = {
  visa: VISA,
  mastercard: MASTERCARD,
  maestro: MAESTRO,
  'american-express': AMERICAN_EXPRESS,
};

export const Payment: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data, updateShowStepper } = useContext(StepContext);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [cardinalInProgress, setCardinalInProgress] = useState(false);
  const minExpiryDate = startOfMonth(new Date());
  const classes = useStyles();
  const history = useHistory();

  const worldpayRef = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => worldpayRef.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    updateActiveStep(6);

    updateShowStepper(true);
  }, []);

  const getCardType = (): string | null => {
    const cct = creditCardType(getValues('cardNumber').toString());
    const libraryCardType = cct[0]?.type === undefined || cct.length > 1 ? null : cct[0].type;
    const localCardType =
      libraryCardType !== null && libraryCardType !== undefined ? libraryToLocalCardTypeMapping[libraryCardType] : null;
    return localCardType !== undefined ? localCardType : null;
  };

  const schema = yup.object().shape({
    cardholdersName: yup
      .string()
      .max(70, "This cardholder name can't be over 70 characters.")
      .required('Please let us know the name on your card.'),
    cardNumber: yup
      .number()
      .typeError('Please enter a valid card number')
      .required('Please let us know your card’s number.')
      .when('cardType', {
        is: () => true,
        then: yup
          .number()
          .typeError('Please let us know your card’s number.')
          .required('Please let us know your card’s number.')
          .test('card-number', 'Invalid card number', (val: number | undefined, { createError }) => {
            const cardType = getCardType();
            if (cardType === MASTERCARD && val) {
              return new RegExp(
                '^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[0-1][0-9]|2720)[0-9]{12,15}$',
              ).test(val?.toString());
            } else if (cardType === VISA && val) {
              return new RegExp('^4[0-9]{12,18}?$').test(val.toString());
            } else if (cardType === AMERICAN_EXPRESS && val) {
              return new RegExp('^3[47][0-9]{13}$').test(val?.toString());
            } else if (cardType === MAESTRO) {
              return createError({
                message: MAESTRO + ' is not supported.',
              });
            } else {
              return false;
            }
          }),
      }),
    cardSecurityNumber: yup
      .string()
      .typeError('Invalid card security number.')
      .required('Please let us know your card’s security number.')
      .test('card-security-number', 'Invalid card security number', (val, context) => {
        const cardType = context.parent.cardType;
        const regex = /^[0-9]+$/;
        const lengthMap = {
          MASTERCARD: 3,
          VISA: 3,
          AMEX: 4,
        };
        if (!cardType) {
          // card type is missing, dont raise this error
          return true;
        }
        return !cardType || (val?.length === lengthMap[cardType] && regex.test(val || ''));
      }),
    cardType: yup.string().required(),
    expiryDate: yup
      .date()
      .typeError('Invalid expiry date.')
      .required('Let us know your card’s expiry date.')
      .min(minExpiryDate, 'Date cannot be before today.')
      .nullable(),
    postcodeLookup: yup.object().shape({
      firstLineOfAddress: yup.string().required('Please enter your postcode.'),
      secondLineOfAddress: yup.string(),
      thirdLineOfAddress: yup.string(),
      town: yup.string().required('Please select an address.'),
      county: yup.string(),
      postcode: yup.string().required('Please enter your postcode.'),
    }),
  });

  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    trigger,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      cardSecurityNumber: '',
      worldpay3dSecureIframe: '',
      threeDSecure: false,
      paymentError: false,
      //IN accordance with legacy, we have line 1, line 2, line 3 (town) - use these, do not use the postcode lookup's third line of address
      // postcodeLookup: {
      //   firstLineOfAddress: data.addressLine1,
      //   secondLineOfAddress: data.addressLine2,
      //   thirdLineOfAddress: data.addressLine3,
      //   town: data.addressLine4,
      //   county: data.addressLine5,
      //   postcode: data.postcode,
      // },
      postcodeLookup: {
        firstLineOfAddress: data.billingAddressLine1 !== '' ? data.billingAddressLine1 : data.addressLine1,
        secondLineOfAddress: data.billingAddressLine2 !== '' ? data.billingAddressLine2 : data.addressLine2,
        thirdLineOfAddress: '',
        town: data.billingAddressLine3 !== '' ? data.billingAddressLine3 : data.addressLine3,
        county: data.billingAddressLine5 !== '' ? data.billingAddressLine5 : data.addressLine5,
        postcode: data.billingAddressPostCode !== '' ? data.billingAddressPostCode : data.postcode,
      },
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const plAddressLine1 = getValues('postcodeLookup.firstLineOfAddress');
  const plAddressLine2 = getValues('postcodeLookup.secondLineOfAddress');
  const plAddressLine3 = getValues('postcodeLookup.thirdLineOfAddress');
  const plAddressLine4 = getValues('postcodeLookup.town');
  const plAddressLine5 = getValues('postcodeLookup.county');
  const plPostcode = watch('postcodeLookup.postcode');
  const worldpay3dSecureIframe = watch('worldpay3dSecureIframe');
  const threeDSecure = watch('threeDSecure');

  const paymentSuccessful = () => {
    updateData({
      ...data,
      ...getValues(),
      billingAddressLine1: plAddressLine1,
      billingAddressLine2: plAddressLine2,
      billingAddressLine3: plAddressLine3,
      billingAddressLine4: plAddressLine4,
      billingAddressLine5: plAddressLine5,
      billingAddressPostCode: plPostcode,
      postcode: plPostcode,
      paymentSuccessful: true,
    });
    setPaymentInProgress(false);
    updateShowStepper(false);
    history.push('/all-sorted');
  };

  useEffect(() => {
    // Update cardType on cardNumber change.
    const cardType = getCardType();
    if (cardType === null) return;
    if (getValues('cardType') !== cardType) {
      setValue('cardType', cardType);
    }
  }, [watch('cardNumber')]);

  useEffect(() => {
    const handleIframeCallback = (event) => {
      let data: null | {
        boolStatus: boolean;
        stringStatus: string;
        orderId: string;
      } = null;

      if (event != null && event.data != null) {
        try {
          data = JSON.parse(event.data);
        } catch {}
      } else {
        return;
      }
      if (!data?.stringStatus || !data?.orderId || data?.boolStatus == null || data?.boolStatus == undefined) {
      } else if (data?.boolStatus) {
        paymentSuccessful();
      } else {
        setValue('paymentError', true);
        setValue('worldpay3dSecureIframe', '');
        trigger('paymentError');
        setPaymentInProgress(false);
        updateData({
          ...data,
          ...getValues(),
          billingAddressLine1: plAddressLine1,
          billingAddressLine2: plAddressLine2,
          billingAddressLine3: plAddressLine3,
          billingAddressLine4: plAddressLine4,
          billingAddressLine5: plAddressLine5,
          billingAddressPostCode: plPostcode,
          postcode: plPostcode,
        });
      }
    };

    if (threeDSecure === false) return;
    window.addEventListener('message', handleIframeCallback, true);
    return () => {
      window.removeEventListener('message', handleIframeCallback, false);
    };
  }, [threeDSecure]);

  const onSubmit = async () => {
    setPaymentInProgress(true);
    setValue('paymentError', false);
    trigger('paymentError');

    const generateJWT = () => {
      const jwtEndPoint =
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_JWT_ENDPOINT}?quote_id=` + data.quote_id;

      const Base64 = /[^A-Z0-9+\/=]/i;

      const getData = async (url) => {
        try {
          const resp = await axios.post(url);
          if (resp.data.token != null && Base64.test(resp.data.token)) {
            return resp.data.token;
          }
        } catch (err) {
          setValue('paymentError', true);
          trigger('paymentError');
          setPaymentInProgress(false);
        }
      };
      return getData(jwtEndPoint);
    };

    const formattedPolicyStartDate = data.coverStartDate ? convertDateToDlgFormat(data.coverStartDate.toString()) : '';

    const startDateForBackend = format(new Date(formattedPolicyStartDate), 'yyyy/MM/dd');

    generateJWT().then((jwtOutput) => {
      const innerHtml =
        "<body onload='document.collectionForm.submit();'>" +
        "<form id='collectionForm' name='collectionForm' method='POST' action=" +
        process.env.REACT_APP_DDC_ENDPOINT +
        '>' +
        "<input type='hidden' name='Bin' value='" +
        getValues('cardNumber').toString().substring(0, 6) +
        "'>" +
        "<input type='hidden' name='JWT' value='" +
        jwtOutput +
        "'>" +
        '</form>' +
        '</body>';

      //set iframe properties
      const iframe = document.createElement('iframe');
      iframe.id = 'ddcIframe';
      iframe.style.visibility = 'hidden';
      iframe.style.display = 'none';
      iframe.srcdoc = innerHtml;

      document.body.appendChild(iframe);

      const ddcTimeout = setTimeout(() => {
        setValue('paymentError', true);
        trigger('paymentError');
        setPaymentInProgress(false);
        setCardinalInProgress(false);
      }, 15000);

      const ddcEvent = async (event) => {
        if (cardinalInProgress === false) {
          setCardinalInProgress(true);
          if (event.origin === `${process.env.REACT_APP_DDC_ORIGIN}`) {
            window.removeEventListener('message', ddcEvent, false);
            const eventData = JSON.parse(event.data);
            if (eventData !== undefined && eventData.Status === true) {
              clearTimeout(ddcTimeout);

              const paymentObject = {
                order: {
                  quote: {
                    //if there is a quoteId (email quote)
                    quoteId: data.quoteId,
                    coverOptions: data.coverOptions,
                    quoteTotal: data.quoteTotal,
                    startDate: startDateForBackend,
                  },
                  quote_id: data.quote_id,
                  partnerizeRef: data.partnerizeRef,
                  businessAddress: {
                    companyName: data.businessCompanyName,
                    firstLine: data.addressLine1,
                    secondLine: data.addressLine2,
                    town: data.addressLine3,
                    postcode: data.postcode,
                  },
                  businessContacts: [
                    {
                      name: data.businessContactName,
                      surname: data.businessContactSurname,
                      telephoneNumber: data.businessContactNumber,
                      emailAddress: data.businessContactEmail,
                    },
                    ...(data.secondPointOfContact
                      ? [
                          {
                            name: data.secondBusinessContactName,
                            surname: data.secondBusinessContactSurname,
                          },
                        ]
                      : []),
                  ],
                  vehicles: [
                    {
                      vehicleType: data.coverType,
                      vehicles: data.vehicles,
                    },
                  ],
                  continuousInd: data.automaticRenewal,

                  cardDetails: {
                    cardHolderName: getValues('cardholdersName'),
                    cardNumber: getValues('cardNumber'),
                    cardType: getValues('cardType'),
                    cardEndDate: format(new Date(getValues('expiryDate') || ''), 'MM/yy'),
                    // cardEndISODate: format(new Date(getValues('expiryDate') || ''), 'MM/yy'),
                    cardSecurityNumber: getValues('cardSecurityNumber'),
                    cardHolderAddress1:
                      `${plAddressLine1 !== '' ? `${plAddressLine1}` : ''}` +
                      `${
                        plAddressLine2 !== ''
                          ? plAddressLine1 !== ''
                            ? ` ${plAddressLine2}`
                            : `${plAddressLine2}`
                          : ''
                      }`,
                    cardHolderTown: plAddressLine4,
                    cardHolderPostCode: plPostcode,
                  },
                  dfReferenceId: eventData.SessionId,
                },
              };

              try {
                const { data: paymentResponse } = await axios.post(
                  `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_ORDER_ENDPOINT}`,
                  paymentObject,
                );

                if (paymentResponse.stringStatus === 'AUTHORISED') {
                  paymentSuccessful();
                } else if (paymentResponse.stringStatus === '3DSECURE') {
                  setCardinalInProgress(false);
                  setValue('worldpay3dSecureIframe', paymentResponse.iframe);
                  setValue('threeDSecure', true);
                  trigger('worldpay3dSecureIframe');
                  trigger('threeDSecure');
                  executeScroll();
                  // @ts-ignore
                  document.getElementById('iframeform').submit();
                } else {
                  setValue('paymentError', true);
                  trigger('paymentError');
                  setPaymentInProgress(false);
                  setCardinalInProgress(false);
                }
              } catch (error) {
                setValue('paymentError', true);
                trigger('paymentError');
                setPaymentInProgress(false);
                setCardinalInProgress(false);
              }
            } else {
              setValue('paymentError', true);
              trigger('paymentError');
              setPaymentInProgress(false);
              setCardinalInProgress(false);
            }
          }
        }
      };
      window.addEventListener('message', ddcEvent, false);
    });
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
      ...getValues(),
      billingAddressLine1: plAddressLine1,
      billingAddressLine2: plAddressLine2,
      billingAddressLine3: plAddressLine3,
      billingAddressLine4: plAddressLine4,
      billingAddressLine5: plAddressLine5,
      billingAddressPostCode: plPostcode,
    });
    history.push(steps[Step.REVIEW].url);
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - Payment`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>

            {!paymentInProgress && (
              <>
                <Typography variant="h1" className={classes.responsivePadding}>
                  Confirm your payment details.
                </Typography>

                <YourPrice
                  cover={data.coverType}
                  price={data.quoteTotal}
                  startDate={data.coverStartDate}
                  vehicleCount={data.vehicleCount}
                  showVehicleWithText={true}
                  data={data}
                />

                {getValues('paymentError') && (
                  <Notice
                    className={clsx(classes.maxWidth35, 'mb3')}
                    heading={paymentSchema.notice.heading}
                    message={paymentSchema.notice.message}
                    messageType="error"
                  />
                )}

                <PaymentDetails
                  cardholdersName={data.cardholdersName}
                  cardNumber={data.cardNumber}
                  expiryDate={data.expiryDate}
                  cardSecurityNumber={''}
                  getCardType={getCardType}
                  control={control}
                  errors={errors}
                  AMERICAN_EXPRESS_TYPE_VALUE={AMERICAN_EXPRESS}
                />

                <Typography variant="h3" className="my2">
                  Billing address
                </Typography>

                <BillingAddress
                  addressLine1={plAddressLine1}
                  addressLine2={plAddressLine2}
                  addressLine3={plAddressLine3}
                  addressLine4={plAddressLine4}
                  addressLine5={plAddressLine5}
                  postcode={plPostcode}
                  plName="postcodeLookup.postcode"
                  plPostcode={plPostcode}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                  error={errors.postcodeLookup ? true : false}
                />

                {errors.postcodeLookup && (
                  <Typography className="fieldError">
                    {errors.postcodeLookup?.firstLineOfAddress
                      ? errors.postcodeLookup?.firstLineOfAddress.message
                      : errors.postcodeLookup?.town
                      ? errors.postcodeLookup?.town.message
                      : errors.postcodeLookup?.postcode
                      ? errors.postcodeLookup.postcode.message
                      : ''}
                  </Typography>
                )}

                <Divider className="my3"></Divider>

                <Box className={clsx(classes.actionButton, 'mb2')}>
                  <ButtonPrimary disabled={paymentInProgress} loading={paymentInProgress} type="submit">
                    Pay Now
                  </ButtonPrimary>
                </Box>
                <TrustBox />
              </>
            )}
          </form>

          <div ref={worldpayRef}>
            {worldpay3dSecureIframe && <div dangerouslySetInnerHTML={{ __html: worldpay3dSecureIframe }} />}
          </div>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Payment;
