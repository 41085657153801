import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  yourVehicleReviewContainer: {
    marginBottom: '3rem',
    '& h2': {
      marginBottom: '2rem',
    },
  },
  px05: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  textRight: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));
