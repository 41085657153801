import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  gridMainContainer: {
    padding: '.5rem 1rem 1rem 1rem',
    margin: 'auto',
    maxWidth: '1280px',
  },
  divGrey: {
    paddingTop: '0rem',
  },
  //For Text between the Dividers
  text: {
    color: theme.palette.common.black,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    textTransform: 'uppercase',
  },
  textAlign: {
    display: 'flex',
    alignItems: 'center',
  },

  // For Buttons
  buttonEdit: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
  },
  backBtn: {
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  //For Accordion
  accordionHeading: {
    fontSize: '1.5rem',
    fontFamily: 'Roboto',
  },
  accordionBody: {
    fontSize: '0.875rem',
  },
  accordionDisplay: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionButton: {
    marginTop: '1rem',
    '& button': {
      minWidth: '13rem',
      '@media (min-width: 321px)': {
        minWidth: '18rem',
      },
    },
    '@media (max-width: 410px)': {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        margin: '1rem 1rem',
      },
      paddingTop: '0',
      marginTop: '0',
    },
  },
}));
