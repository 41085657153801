import React, { useContext, useState, useEffect } from 'react';
import {
  DocumentTitle,
  ButtonPrimary,
  Typography,
  LinkNavigation,
  YourPrice,
  StepChecker,
} from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './VehicleDetailsStyles';
import { StepContext, IStepData, steps, Step, VehicleData } from '../../contexts/StepContext';
import { Grid, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { VehicleLookUp } from '../../components/molecules/VehicleLookup';
import clsx from 'clsx';

export const VehicleDetails: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data } = useContext(StepContext);

  /*TODO: Simple tracking of a "hasError" state in case we want to prevent 
  continue or display a message. This is set from submitClickHandle. 
  However - this should be cleared once the focus is reset to the inner components 
  */
  const [hasErrors, setHasErrors] = useState(false);
  const [vehicles] = useState<Array<VehicleData>>(data.vehicles);
  const [vehicleDataLookup, setVehicleDataLookup] = useState(data.vehicles);
  const [vehicleIndex, setVehicleIndex] = useState(
    data.vehicles?.length > 0
      ? data.vehicles.reduce((p, c) => (p.reactIndex > c.reactIndex ? p : c)).reactIndex
      : data.vehicles.length,
  );

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    updateActiveStep(2);
    window.scrollTo(0, 0);
  }, []);

  const { handleSubmit, control, trigger } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      data,
      vehicles,
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  // Handler for the click button - the natural default is to submit
  // but if errors are found, this is prevented and hasErrors is set or cleared
  const submitClickHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    const foundErrors =
      vehicleDataLookup.length < 1 || vehicleDataLookup.filter((vehicle) => !vehicle.complete).length > 0;
    if (foundErrors) {
      e.preventDefault();
      // handle page error state
    }
    setHasErrors(foundErrors);
  };

  const coverOptionsData: string[] = [];
  const formData = data.coverOptions;

  if (formData?.Items) {
    formData?.Items.map((element) => {
      if (element.coverType === data.coverType) {
        element['price'] = data.quoteTotal;
        element['number'] = data.vehicleCount;
      } else {
        element['price'] = 0;
        element['number'] = 0;
      }
      return coverOptionsData.push(element);
    });
  } else {
    formData?.map((element) => {
      if (element.coverType === data.coverType) {
        element['price'] = data.quoteTotal;
        element['number'] = data.vehicleCount;
      } else {
        element['price'] = 0;
        element['number'] = 0;
      }
      return coverOptionsData.push(element);
    });
  }

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      vehicles: vehicleDataLookup,
      coverOptions: coverOptionsData,
    });

    history.push(steps[Step.BUSINESS_DETAILS].url);
  };

  const handleBackButton = () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
      vehicles: vehicleDataLookup,
      coverOptions: coverOptionsData,
    });

    if (data.quoteId !== null) {
      history.push({ pathname: steps[Step.YOUR_QUOTE].url, search: `?id=${data.quoteId}` });
    } else {
      history.push(steps[Step.YOUR_QUOTE].url);
    }
  };

  // Wrapper for "handleVehicleData" - passed to the VehicleLookUp component as a function
  //TODO: more functions like this may be needed to prevent the "update a component from another component" warnings
  const handleVehicleData = (data) => {
    setVehicleDataLookup(data);
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - Vehicle Details`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <LinkNavigation className="mb3" onClick={handleBackButton}>
            Back
          </LinkNavigation>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Typography className={clsx(classes.detailsCorrectHeading, 'mb3')} variant="h1">
              Let us know the details of each vehicle that needs cover.
            </Typography>
            <div>
              {hasErrors ? (
                <div className="pt1">
                  <Typography className="fieldError">
                    Your form has some errors. Please complete your vehicle data.
                  </Typography>
                </div>
              ) : null}

              <VehicleLookUp
                vehicleCount={data.vehicleCount}
                vehicleDetails={data.vehicles}
                vehicleDetailsLookup={handleVehicleData}
                trigger={trigger}
                control={control}
                showErrors={hasErrors}
              />

              <div className="mt2">
                <YourPrice
                  cover={data.coverType}
                  price={data.quoteTotal}
                  startDate={data.coverStartDate}
                  vehicleCount={data.vehicleCount}
                  data={data}
                />
              </div>

              {hasErrors ? (
                <div className="pt1">
                  <Typography className="fieldError">
                    Your form has some errors. Please complete your vehicle data.
                  </Typography>
                </div>
              ) : null}

              <Box className={classes.actionButton}>
                <ButtonPrimary type="submit" onClick={submitClickHandle}>
                  Continue
                </ButtonPrimary>
              </Box>
            </div>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default VehicleDetails;
