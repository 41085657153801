import React, { useContext, useEffect } from 'react';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  LinkNavigation,
  ButtonPrimary,
  YourCoverReview,
  YourVehiclesReview,
  BusinessDetails,
} from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ReviewStyles';
import { StepContext, steps, Step } from '../../contexts/StepContext';
import { Grid, Divider, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

declare global {
  interface Window {
    gtag: any;
  }
};

export const Review: React.FC = () => {
  const { activeStep, updateActiveStep, data } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  
  useEffect(() => {
    updateActiveStep(5);

    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: 'DC-3535201/gffleet/revfleet+standard',
    });
  }, []);

  const handleContinue = () => {
    updateActiveStep(activeStep + 1);
    history.push(steps[Step.PAYMENT].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    history.push(steps[Step.IMPORTANTINFO].url);
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - Review`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <LinkNavigation onClick={handleBack}>Back</LinkNavigation>

          <Typography variant={'h1'} className="my2">
            Please make sure all the details below are correct.
          </Typography>

          <YourCoverReview data={data} />
          <YourVehiclesReview data={data} />
          <BusinessDetails data={data} />

          <Divider className="divider"></Divider>
          <Typography variant={'h2'} className="my2">
            If everything looks good, let&apos;s go make this official.
          </Typography>
          <Box className={clsx(classes.actionButton, 'pt1')}>
            <ButtonPrimary onClick={handleContinue}>Continue</ButtonPrimary>
          </Box>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Review;
