import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import MuiModal from '@material-ui/core/Modal';
import { useStyles } from './ModalStyles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { ButtonPrimary } from '../ButtonPrimary';

export interface IModal {
  isOpen: boolean;
  onCloseClick: () => void;
  children: React.ReactNode;
  ariaLabelledBy: string;
  testId?: string;
  isSmallClose?: boolean;
  isBorderStyle?: boolean;
  isCloseBtn?: boolean;
}

const MuModal: React.FC<IModal> = ({
  isOpen,
  onCloseClick,
  children,
  ariaLabelledBy,
  isSmallClose,
  isBorderStyle,
  isCloseBtn,
}: IModal): JSX.Element => {
  const classes = useStyles();
  return (
    <MuiModal disablePortal aria-labelledby={ariaLabelledBy} open={isOpen} onClose={onCloseClick}>
      <>
        <Box className={clsx(classes.modal, isBorderStyle ? classes.borderStyle : '')}>
          <Box className="py1">
            <IconButton className={classes.iconButton} aria-label="Close" onClick={onCloseClick}>
              <CloseIcon className={isSmallClose ? classes.closeIconSmall : classes.closeIcon} />
            </IconButton>
          </Box>
          <div className={clsx(classes.paper, 'mt2')}>{children} </div>
          {isCloseBtn ? (
            <Box className={classes.footerStyling}>
              <ButtonPrimary onClick={onCloseClick}>Close</ButtonPrimary>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </>
    </MuiModal>
  );
};

export default MuModal;
