import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  gridMainContainer: {
    padding: '.5rem 1rem 1rem 1rem',
    margin: 'auto',
    maxWidth: '1280px',
  },
  detailsCorrectHeading: {
    maxWidth: '35rem',
  },
  actionButton: {
    marginTop: '1rem',
    '& button': {
      minWidth: '13rem',
      '@media (max-width: 500px)': {
        minWidth: '100%',
      },
    },
    '@media (max-width: 410px)': {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        margin: '1rem 1rem',
      },
      paddingTop: '0',
      marginTop: '0',
    },
  },
}));
