import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    nameOnCardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '31rem',
      width: '100%',
    },
    cardContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    visaImage: {
      height: 'auto',
      width: '2rem',
      marginRight: '1rem',
    },
    americanExpressImage: {
      height: 'auto',
      width: '2rem',
      marginRight: '0.75rem',
    },
    mastercardImage: {
      height: 'auto',
      width: '2rem',
    },
    cardholdersNameField: {
      maxWidth: '31rem',
      width: '100%',
    },
    cardNumberField: {
      maxWidth: '31rem',
      width: '100%',
    },
    expiryDateField: {
      maxWidth: '11.25rem',
      width: '100%',
    },

    cardSecurityNumberField: {
      maxWidth: '11.25rem',
      width: '100%',
    },
    adornment: {
      fontSize: 20,
    },
    expiryAdornment: {
      display: 'none',
    },
    creditCardAdornment: {
      overflow: 'inherit',
      paddingBottom: '1rem',
      height: '35px',
      width: '16px',
    },
  }),
  { index: 1 },
);
