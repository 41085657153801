import React from 'react';
import theme from './theme';
import './fonts.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StepProvider, steps, Step } from './contexts/StepContext';
import {
  YourQuote,
  BusinessDetails,
  ImportantInfo,
  Payment,
  AllSorted,
  StyleGuide,
  VehicleDetails,
  Review,
  Timeout,
} from './pages';
import GlobalCss from './globalCss';
import { StylesProvider } from '@material-ui/core/styles';
// import { ErrorBoundary } from './components/atoms/ErrorBoundary';

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <GlobalCss />
          {/* <ErrorBoundary> potential before go live revert, need to discuss */}
          <StepProvider>
            <Switch>
              <Route exact path="/" component={YourQuote} />
              <Route path={`/?id=:id`} component={YourQuote} />
              <Route path={`/?clickref=:clickref`} component={YourQuote}/>
              <Route exact path={`${steps[Step.YOUR_QUOTE].url}/:id`} component={YourQuote} />
              <Route exact path={steps[Step.VEHICLE_DETAILS].url} component={VehicleDetails} />
              <Route exact path={steps[Step.BUSINESS_DETAILS].url} component={BusinessDetails} />
              <Route exact path={steps[Step.IMPORTANTINFO].url} component={ImportantInfo} />
              <Route exact path={steps[Step.REVIEW].url} component={Review} />
              <Route exact path={steps[Step.PAYMENT].url} component={Payment} />
              <Route exact path="/all-sorted" component={AllSorted} />
              <Route exact path="/timeout" component={Timeout}/>
              {process.env.REACT_APP_INCLUDE_TEST_PAGES === 'true' && (
                <Route exact path="/guide" component={StyleGuide} />
              )}
            </Switch>
          </StepProvider>
          {/* </ErrorBoundary> */}
        </StylesProvider>
      </ThemeProvider>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
