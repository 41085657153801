import React, { useContext, useEffect, useState } from 'react';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  LinkNavigation,
  Accordion,
  ButtonSecondary,
  Checkbox,
  Divider,
  ButtonPrimary,
  MuModal,
} from '../../components/atoms';
import { Grid, Box, FormControl } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ImportantInfoStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import importantInfoSchema from './ImportantSchema';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as yup from 'yup';
import clsx from 'clsx';

export const ImportantInfo: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData } = useContext(StepContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    updateActiveStep(4);
  }, []);

  const schema = yup.object().shape({
    meetsVehicleCriteria: yup.bool().oneOf([true], 'Please confirm the above to continue.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      meetsVehicleCriteria: false,
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
    });
    history.push(steps[Step.REVIEW].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
    });
    history.push(steps[Step.BUSINESS_DETAILS].url);
  };

  const handleAutomaticRenewal = (isRenewal) => {
    setIsModalOpen(false);
    updateData({
      ...data,
      automaticRenewal: isRenewal,
    });
  };

  const getDocument = (filePath: string) => window.open(filePath);

  const {
    rightToCancel,
    complaints,
    financialCompensation,
    informationUsage,
    englishLawApplies,
    aboutAutomaticRenewals,
  } = importantInfoSchema;

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - Important Information`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>
            <Typography className="pt2" variant="h1">
              Important information
            </Typography>
            <Typography className="pt3" variant="h2">
              Keeping you covered, 24/7.
            </Typography>
            <Typography className={clsx(classes.reponsiveWidth, 'pt2')}>
              A few weeks before your policy ends, we&apos;ll send over your renewal quote.
            </Typography>
            <Typography className="pt1">
              If you&apos;re happy with your quote, you won&apos;t need to get in touch. You&apos;ll stay covered for
              another year.
            </Typography>
            <Typography className="pt1">
              And don&apos;t worry, you can still cancel or change your cover if you need to.
            </Typography>
            <Typography className="pt1">
              You can change your renewal preference at any time during your policy. Or, to see your renewal options
              now,{' '}
              <span
                className={classes.underlineText}
                onClick={() => {
                  event?.preventDefault();
                  setIsModalOpen(true);
                }}
              >
                tap here
              </span>
              .
            </Typography>
            <Typography className="pt3" variant="h2">
              Important statements
            </Typography>
            <Typography className={clsx(classes.reponsiveWidth, 'pt2')}>
              Please make sure all the information you have told us is correct and complete and your vehicle meets the
              criteria below. Your price is worked out using this information and if anything you&apos;ve told us is
              incorrect or incomplete, you might not be covered. Your policy will run for 12 months from your cover
              start date.
            </Typography>
            <Typography className="pt2">Your vehicle(s) must meet these criteria:</Typography>

            <Box className={clsx(classes.reponsiveWidth, classes.importantStatementsList)}>
              <ul className="pl1">
                <li className="pl1 pb1">
                  <Typography>
                    It&apos;s either a car, light van, motorhome or motorbike
                  </Typography>
                </li>
                <li className="pl1 pb1">
                  <Typography>
                    It&apos;s privately or commercially registered in the UK
                  </Typography>
                </li>
                <li className="pl1 pb1">
                  <Typography>
                    There aren&apos;t more people in it than the manufacturer would recommend, or more than nine
                    altogether including the driver
                  </Typography>
                </li>
                <li className="pl1 pb1">
                  <Typography>
                    It can&apos;t weigh more than 4 metric tons (4,000kg) in total, including any load carried
                  </Typography>
                </li>
                <li className="pl1 pb1">
                  <Typography>
                    It can&apos;t be more than 7 metres long (apart from a tow bar or coupling device), 3 metres tall and
                    2.55 metres wide
                  </Typography>
                </li>
                <li className="pl1 pb1">
                  <Typography>
                    It&apos;s been serviced, looked after and used as recommended by the manufacturer
                  </Typography>
                </li>
                <li className="pl1 pb1">
                  <Typography>
                    It&apos;s got a valid road tax and MOT certificate, if it needs one and meets any legal requirements
                    and driving laws that apply
                  </Typography>
                </li>
                <li className="pl1 pb1">
                  <Typography>
                    It must be fit to drive when you take out the policy and at the start of each journey
                  </Typography>
                </li>
                <li className="pl1 pb1">
                  <Typography>
                    It&apos;s never used to carry things or people for money. For example, as a courier service or taxi
                  </Typography>
                </li>
              </ul>
              <Typography>
                If your circumstances change between the date you purchase the policy and your policy&apos;s start date,
                please contact us. Failure to inform us of any changes could invalidate all or part of the policy and
                result in claims being rejected or not fully paid.
              </Typography>
              <Typography>
                Please see the{' '}
                <a
                  href="#"
                  className="anchorLink"
                  onClick={() => getDocument('pdfs/Fleet-Policy.pdf')}
                  rel="noreferrer"
                >
                  policy wording
                </a>{' '}
                for more details on the types of vehicle we cover.
              </Typography>
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt2')}>
              <Accordion heading={rightToCancel.heading} body={rightToCancel.body} />
              <Accordion heading={complaints.heading} body={complaints.body} />
              <Accordion heading={financialCompensation.heading} body={financialCompensation.body} />
              <Accordion heading={informationUsage.heading} body={informationUsage.body} />
              <Accordion heading={englishLawApplies.heading} body={englishLawApplies.body} />
              <Accordion heading={aboutAutomaticRenewals.heading} body={aboutAutomaticRenewals.body} />
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt1')}>
              <Typography variant="h3">Insurance product information document (IPID)</Typography>
              <Typography className="pt1">
                Here&apos;s a top-line summary of what your policy will and won&apos;t cover. Please note, it&apos;s not
                personalised to you.
              </Typography>
              <ButtonSecondary
                className="mt1"
                onClick={() => getDocument('pdfs/Fleet-IPID.pdf')}
                endIcon={<GetAppIcon fontSize="large" />}
              >
                Download PDF (2.8MB)
              </ButtonSecondary>
              <Typography variant="h3" className="pt2">
                Breakdown cover policy booklet
              </Typography>
              <Typography className="pt1">
                You can find out everything about our cover in this policy booklet.
              </Typography>
              <ButtonSecondary
                className="mt1"
                onClick={() => getDocument('pdfs/Fleet-Policy.pdf')}
                endIcon={<GetAppIcon fontSize="large" />}
              >
                Download PDF (833kb)
              </ButtonSecondary>
            </Box>
            <Divider className={clsx(classes.reponsiveWidth, 'divider mt4')}></Divider>
            <Box className={clsx(classes.reponsiveWidth, 'pt2')}>
              <FormControl component="fieldset">
                <Checkbox
                  name="meetsVehicleCriteria"
                  label="I confirm that the above statements are true and that my vehicles meet the criteria listed."
                  watch={watch}
                  control={control}
                  trigger={trigger}
                  error={errors.meetsVehicleCriteria ? true : false}
                  color="primary"
                />
              </FormControl>
              <Typography className="fieldError">{errors.meetsVehicleCriteria?.message}</Typography>
            </Box>

            <Divider className={clsx(classes.reponsiveWidth, 'divider mt4')}></Divider>

            <Box className={clsx(classes.actionButton, 'pt1')}>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </Box>
          </form>
          <MuModal
            isOpen={isModalOpen}
            onCloseClick={() => setIsModalOpen(false)}
            ariaLabelledBy={'Your renewal options.'}
            isSmallClose={true}
          >
            <Typography variant="h2">Your renewal options.</Typography>
            <Typography className="pt2">You can choose to opt out of automatic renewal now.</Typography>
            <Typography className="pt1">
              If you do opt out, you&apos;ll have to contact us before your policy ends to stay covered.
            </Typography>
            <Typography className="pt1">
              If you&apos;re not sure now, you can always opt out of auto renewal at a later date.
            </Typography>
            <Typography className="pt2" variant="h3">
              Would you like to renew automatically?
            </Typography>
            <Box className={clsx(classes.actionButton, 'pt1')}>
              {data.automaticRenewal === 'Yes' ? (
                <ButtonPrimary className={classes.buttonWidth} onClick={() => handleAutomaticRenewal('Yes')}>
                  Yes
                </ButtonPrimary>
              ) : (
                <ButtonSecondary className={classes.buttonWidth} onClick={() => handleAutomaticRenewal('Yes')}>
                  Yes
                </ButtonSecondary>
              )}

              {data.automaticRenewal === 'No' ? (
                <ButtonPrimary
                  className={clsx(classes.buttonWidth, 'ml1')}
                  onClick={() => handleAutomaticRenewal('No')}
                >
                  No
                </ButtonPrimary>
              ) : (
                <ButtonSecondary
                  className={clsx(classes.buttonWidth, 'ml1')}
                  onClick={() => handleAutomaticRenewal('No')}
                >
                  No
                </ButtonSecondary>
              )}
            </Box>
          </MuModal>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default ImportantInfo;
