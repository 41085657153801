import React, { useContext, useEffect } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ButtonPrimary,
  ButtonSecondary,
  Typography,
  TextField,
  Divider,
  LinkNavigation,
  BillingAddress,
} from '../../components/atoms';

import { Grid, InputLabel, Box } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './BusinessDetailsStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';

export const BusinessDetails: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    updateActiveStep(3);
  }, []);

  const secondPointOfContactLocal = data.secondPointOfContact ? 'true' : 'false';

  const schema = yup.object().shape({
    businessCompanyName: yup.string().required('Let us know your company name.'),
    postcodeLookup: yup.object().shape({
      firstLineOfAddress: yup.string().required('Please enter a valid postcode.'),
      secondLineOfAddress: yup.string(),
      thirdLineOfAddress: yup.string(),
      town: yup.string().required('Please select an address.'),
      county: yup.string(),
      postcode: yup.string().required('Please enter a valid postcode.'),
    }),
    businessContactName: yup.string().required('Please let us know their first name.'),
    businessContactSurname: yup.string().required('Please let us know their last name.'),
    businessContactNumber: yup
      .number()
      .typeError('Contact number should contain numbers only.')
      .required('Please tell us their contact number.'),
    businessContactEmail: yup
      .string()
      .email('Please enter a valid email address.')
      .required('Please enter a valid email address.'),

    secondBusinessContactName: yup.string().when('secondPointOfContactLocal', {
      is: () => secondPointOfContactLocal === 'true',
      then: yup.string().required('Please let us know their first name.'),
    }),
    secondBusinessContactSurname: yup.string().when('secondPointOfContactLocal', {
      is: () => secondPointOfContactLocal === 'true',
      then: yup.string().required('Please let us know their last name.'),
    }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      businessCompanyName: data.businessCompanyName,
      businessContactName: data.businessContactName,
      businessContactSurname: data.businessContactSurname,
      postcodeLookup: {
        firstLineOfAddress: data.addressLine1,
        secondLineOfAddress: data.addressLine2,
        thirdLineOfAddress: '',
        town: data.addressLine3,
        county: data.addressLine5,
        postcode: data.postcode,
      },
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const plAddressLine1 = getValues('postcodeLookup.firstLineOfAddress');
  const plAddressLine2 = getValues('postcodeLookup.secondLineOfAddress');
  const plAddressLine3 = getValues('postcodeLookup.thirdLineOfAddress');
  const plAddressLine4 = getValues('postcodeLookup.town');
  const plAddressLine5 = getValues('postcodeLookup.county');
  const plPostcode = watch('postcodeLookup.postcode');

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
      ...getValues(),
      secondPointOfContact: data.secondPointOfContact,
      secondBusinessContactName: getValues('secondBusinessContactName'),
      secondBusinessContactSurname: getValues('secondBusinessContactSurname'),
      addressLine1: plAddressLine1,
      addressLine2: plAddressLine2,
      addressLine3: plAddressLine4,
      addressLine4: '',
      addressLine5: plAddressLine5,
      postcode: plPostcode,
    });
    history.push(steps[Step.IMPORTANTINFO].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);

    history.push(steps[Step.VEHICLE_DETAILS].url);
  };

  // Prevent onKeyPress form submission
  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && e.preventDefault();
  };

  const addSecondPointOfContact = () => {
    updateData({
      ...data,
      secondPointOfContact: true,
      secondBusinessContactName: '',
      secondBusinessContactSurname: '',
    });
    setValue('secondBusinessContactName', '');
    setValue('secondBusinessContactSurname', '');
  };

  const RemoveSecondPointOfContact = () => {
    updateData({
      ...data,
      secondPointOfContact: false,
      secondBusinessContactName: '',
      secondBusinessContactSurname: '',
    });
    setValue('secondBusinessContactName', '');
    setValue('secondBusinessContactSurname', '');
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - Business Details`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>

            <Box>
              <Typography className="pt2" variant="h1">
                We need some final details about your business.
              </Typography>
              <Typography className="pt2" variant="h2">
                Business name and address.
              </Typography>
              <InputLabel className="inputLabel pt2 pb1" htmlFor="businessCompanyName">
                Company name
              </InputLabel>
              <TextField
                className={clsx(classes.inputFields)}
                id="businessCompanyName"
                name="businessCompanyName"
                control={control}
                defaultValue={data.businessCompanyName}
                onKeyPress={handleOnKeyPress}
                error={errors.businessCompanyName ? true : false}
              />
              <Typography className="fieldError">{errors.businessCompanyName?.message}</Typography>
            </Box>
            <Box className="mt2">
              <BillingAddress
                addressLine1={plAddressLine1}
                addressLine2={plAddressLine2}
                addressLine3={plAddressLine3}
                addressLine4={plAddressLine4}
                addressLine5={plAddressLine5}
                postcode={plPostcode}
                plName="postcodeLookup.postcode"
                plPostcode={plPostcode}
                setValue={setValue}
                trigger={trigger}
                control={control}
                error={errors.postcodeLookup ? true : false}
              />

              {errors.postcodeLookup && (
                <Typography className="fieldError">
                  {errors.postcodeLookup?.firstLineOfAddress
                    ? errors.postcodeLookup?.firstLineOfAddress.message
                    : errors.postcodeLookup?.town
                    ? errors.postcodeLookup?.town.message
                    : errors.postcodeLookup?.postcode
                    ? errors.postcodeLookup.postcode.message
                    : ''}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography className="pt2" variant="h2">
                Business point of contact.
              </Typography>
              <Typography className="pt1" variant="body1">
                This person can get in touch with us to discuss your policy and make any amendments if needed.
                <br /> You can also add a second point of contact below.
              </Typography>

              <InputLabel className="inputLabel pt2 pb1" htmlFor="businessContactName">
                First name
              </InputLabel>
              <TextField
                className={classes.inputFields}
                id="businessContactName"
                name="businessContactName"
                control={control}
                defaultValue={data.businessContactName}
                onKeyPress={handleOnKeyPress}
                error={errors.businessContactName ? true : false}
              />
              <Typography className="fieldError">{errors.businessContactName?.message}</Typography>

              <InputLabel className="inputLabel pt2 pb1" htmlFor="businessContactSurname">
                Last name
              </InputLabel>
              <TextField
                className={classes.inputFields}
                id="businessContactSurname"
                name="businessContactSurname"
                control={control}
                defaultValue={data.businessContactSurname}
                onKeyPress={handleOnKeyPress}
                error={errors.businessContactSurname ? true : false}
              />
              <Typography className="fieldError">{errors.businessContactSurname?.message}</Typography>

              <InputLabel className="inputLabel pt2 pb1" htmlFor="businessContactNumber">
                Contact number
              </InputLabel>
              <TextField
                className={classes.inputFields}
                id="businessContactNumber"
                name="businessContactNumber"
                control={control}
                defaultValue={data.businessContactNumber}
                onKeyPress={handleOnKeyPress}
                error={errors.businessContactNumber ? true : false}
              />
              <Typography className="fieldError">{errors.businessContactNumber?.message}</Typography>

              <InputLabel className="inputLabel pt2 pb1" htmlFor="businessContactEmail">
                Email address
              </InputLabel>
              <TextField
                className={classes.inputFields}
                id="businessContactEmail"
                name="businessContactEmail"
                control={control}
                defaultValue={data.businessContactEmail}
                onKeyPress={handleOnKeyPress}
                error={errors.businessContactEmail ? true : false}
              />
              <Typography className="fieldError">{errors.businessContactEmail?.message}</Typography>

              <Box className={classes.maxWidth24}>
                <Typography className="pt2">
                  We&apos;ll only use these contact details if we need to get in touch about your policy
                </Typography>
              </Box>
              

              {!data.secondPointOfContact ? (
                <Box className={classes.actionButton}>
                  <ButtonSecondary className={clsx('my1')} onClick={addSecondPointOfContact}>
                    Add second point of contact
                  </ButtonSecondary>
                </Box>
              ) : (
                <></>
              )}
              {data.secondPointOfContact ? (
                <>
                  <Typography className="pt3" variant="h2">
                    Second point of contact.
                  </Typography>
                  <Box className={classes.maxWidth24}>
                    <Typography className="pt1">
                      Add a second point of contact here. We’ll keep their name on our records so they can also get in
                      touch to discuss your policy
                    </Typography>
                  </Box>
                  <InputLabel className="inputLabel pt2 pb1" htmlFor="secondBusinessContactName">
                    First name
                  </InputLabel>
                  <TextField
                    className={classes.inputFields}
                    id="secondBusinessContactName"
                    name="secondBusinessContactName"
                    control={control}
                    defaultValue={data.secondBusinessContactName}
                    onKeyPress={handleOnKeyPress}
                    error={errors.secondBusinessContactName ? true : false}
                  />
                  <Typography className="fieldError">{errors.secondBusinessContactName?.message}</Typography>

                  <InputLabel className="inputLabel pt2 pb1" htmlFor="secondBusinessContactSurname">
                    Last name
                  </InputLabel>
                  <TextField
                    className={classes.inputFields}
                    id="secondBusinessContactSurname"
                    name="secondBusinessContactSurname"
                    control={control}
                    defaultValue={data.secondBusinessContactSurname}
                    onKeyPress={handleOnKeyPress}
                    error={errors.secondBusinessContactSurname ? true : false}
                  />
                  <Typography className="fieldError">{errors.secondBusinessContactSurname?.message}</Typography>
                </>
              ) : (
                <></>
              )}

              {data.secondPointOfContact ? (
                <Box className={classes.actionButton}>
                  <ButtonSecondary className={'my2'} onClick={RemoveSecondPointOfContact}>
                    Remove second point of contact
                  </ButtonSecondary>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Divider className="divider mt2"></Divider>

            <Box className={classes.actionButton}>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </Box>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default BusinessDetails;
